import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RecommendTop from "../../components/RecommendTop.js";
import RecommendOther from "../../components/RecommendOther.js";

const allRecommendations = require("../recommendations.json");

// mark
const recommendPlaceInfo = allRecommendations[0];

const TestPage = () => {
  return (
    <>
      <Head
        title={`Austin City Tacos Recommends: ` + recommendPlaceInfo.title}
        description={`One of our top recommendations is ${recommendPlaceInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Austin City Tacos Recommends: {recommendPlaceInfo.title}</h1>

              <RecommendTop data={recommendPlaceInfo}></RecommendTop>

              <div className="big-description">
                <p>
                  Las Trancas is one of the highlights on Cesar Chavez,
                  including air-conditioning and lively atmosphere. They serve
                  street tacos so the sizes are appropriately small and amazing.
                  Our top choices are the carne asada and pastor, with many
                  close behind.
                </p>
                <p>
                  We typically visit since it is so close to downtown on Cesar
                  Chavez, so after hitting up the bars it's the perfect combo.
                  One of Las Trancas' strong draws is the broad menu that covers
                  most of the popular options with tacos and quesadillas. If you
                  love crispy tripas, then we have great news for you. There was
                  an ominous rumour going around that{" "}
                  <a
                    href="https://www.bravotv.com/people/paul-qui"
                    target="_blank"
                  >
                    Paul Qui
                  </a>{" "}
                  (from Top Chef) is a frenquent visitor, but no sightings so
                  far.
                </p>
              </div>
            </div>

            <div className="right-sidebar">
              <RecommendOther title={recommendPlaceInfo.title}></RecommendOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestPage;
